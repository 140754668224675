<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">直播记录列表</h3>
    </div>
    <div class="box">
      <div class="roomlist">
        <div class="tiaojian">
          <div class="left">
            <strong>直播间名称: </strong>
            <el-input
              v-model="title"
              placeholder="请输入直播间名称以筛选"
            ></el-input>
          </div>
          <span class="shaixuan shou" @click="filterList">筛选</span>
        </div>

        <el-table :data="liveRoomList" border style="width: 100%">
          <el-table-column type="expand">
            <div class="orderGoods" slot-scope="scope">
              <h4>直播商品</h4>
              <div
                class="goodsDetail"
                v-for="item in scope.row.goods"
                :key="item.id"
              >
                <div class="img">
                  <img :src="imgurl + item.pic" alt="" />
                </div>
                <div class="data">
                  <p class="sheng">{{ item.goods_name }}</p>
                  <p class="sheng">
                    数量:<span>{{ item.goods_num }}</span>
                  </p>
                  <p class="sheng">
                    小计: <span>{{ item.goods_total_price }}</span
                    >元
                  </p>
                </div>
              </div>
            </div>
          </el-table-column>
          <el-table-column prop="title" label="主播头像" width="80">
            <div slot-scope="scope">
              <img style="width:50px; height:50px; border-radius:50%" :src="scope.row.avatar" alt="">
            </div>
          </el-table-column>
          <el-table-column prop="title" label="主播姓名">
            <div slot-scope="scope">
              {{ scope.row.username }}
            </div>
          </el-table-column>
          <el-table-column prop="name" label="直播名称">
            <div slot-scope="scope">
              {{ scope.row.title }}
            </div>
          </el-table-column>
          <el-table-column prop="address" width="400" label="直播时长">
            <div style="color: #19bdff" slot-scope="scope">
              {{ scope.row.starttime | datefilter }}---{{
                scope.row.endtime | datefilter
              }}
            </div>
          </el-table-column>
          <el-table-column prop="address" label="直播时间">
            <div style="color: #19bdff" slot-scope="scope">
              {{ (scope.row.duration / 60).toFixed(2) }} 分钟
            </div>
          </el-table-column>
          <el-table-column prop="address" label="直播观看人数">
            <div slot-scope="scope">
              {{ scope.row.online }}
            </div>
          </el-table-column>
          <el-table-column prop="address" label="直播销量">
            <div slot-scope="scope">
              {{ scope.row.order_num }}
            </div>
          </el-table-column>
          <el-table-column prop="address" label="直播营业额">
            <div slot-scope="scope">
              {{ scope.row.order_pay_price }}
            </div>
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <router-link :to="{path:'/live/closeInviteRanking',query: {liveid:scope.row.live_id, playback_id: scope.row.id}}">
                  <el-button
                    type="primary"
                    round
                    size="mini"
                    style="margin-left:10px"
                >门店邀约排行</el-button>
              </router-link>
              <router-link :to="{path:'/live/commodityRanking',query: {liveid:scope.row.live_id, playback_id: scope.row.id}}">
                  <el-button
                    type="primary"
                    round
                    size="mini"
                    style="margin-left:10px"
                >商品销量排行</el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          style="margin-top: 20px"
          @current-change="crtChg"
          :current-page="page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>
<script>
import {
  findLiveRoomList,
  deleteLiveRoom,
  closeLiveRoom,
  getLivePlaybackList,
} from "@/api/api.js";
import { getSystemsettings } from "../../api/getSystemsetting.js";
export default {
  props: ["id"],
  data() {
    return {
      page: 1,
      limit: 10,
      title: "",
      total: 1,
      liveRoomList: [],
      imgurl:''
    };
  },
  methods: {
    crtChg(e) {
      console.log(e);
      this.page = e;
      this.getlist();
    },
    filterList() {
      this.page = 1;
      this.getlist();
    },

    // 获取直播间列表
    async getlist() {
      let datas = {
        title: this.title,
        page: this.page,
        limit: this.limit,
      };
      const { data } = await getLivePlaybackList(datas);
      this.total = data.data.total;
      this.liveRoomList = data.data.data;
    },
  },

  created() {
    // alert('4')
    this.getlist();
  },
  mounted() {
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
    this.getlist();
  },
};
</script>
<style lang="less" scope>
.box-card {
  flex: 1;
  .box {
    .btnbox {
      margin-bottom: 20px;
      .addliveroom {
        background-color: #009688;
        color: #fff;
        padding: 6px 10px;
        border-radius: 3px;
      }
    }

    .tiaojian {
      margin: 20px 0;
      padding: 10px;
      display: flex;
      align-items: center;
      background-color: #edeff0;
      position: relative;
      .left {
        strong {
          font-weight: normal;
          font-size: 14px;
          color: #606266;
          padding-top: 10px;
          margin-right: 2px;
        }
        .el-input {
          width: 300px;
          margin-right: 20px;
          /deep/.el-input__inner {
            height: 30px;
          }
        }
        .checkboxBox {
          padding-top: 10px;
          margin: 0 0 20px 0;
        }
      }

      .shaixuan {
        background-color: #18bdff;
        color: aliceblue;
        padding: 3px 10px;
        border-radius: 2px;
        position: absolute;
        bottom: 15px;
        left: 400px;
        &:hover {
          background-color: #1abe9c;
        }
      }
    }

    .roomlist {
      .title {
        font-size: 13px;
        background-color: #eee;
        padding: 10px;
        margin: 5px 0 20px 0;
        p {
          input {
            height: 30px;
            margin-left: 5px;
            font-size: 12px;
          }
        }
      }
    }

    .orderGoods {
      border: 1px solid #f1f1f1;
      padding: 10px;
      h4 {
        margin: 0px 0 10px 15px;
        font-weight: normal;
        font-size: 18px;
      }
      .goodsDetail {
        background-color: #f1f3f4;
        margin-right: 50px;
        padding: 10px;
        margin-bottom: 5px;
        display: flex;
        .img {
          width: 77px;
          height: 77px;
          background-color: #e9ebec;
          margin-right: 10px;
          img {
            width: 100%;
          }
        }
        .data {
          p {
            &:nth-child(2) {
              margin: 15px 0;
            }
            span {
              color: #db5376;
            }
          }
        }
      }
    }

  }
}
</style>